<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="vinculosEntidade"
          dataKey="id"
          :filters.sync="filtros"
          :globalFilterFields="['codigoVinculo', 'vinculo.nome', 'maxParcelas']"
          filterDisplay="menu"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} vínculos"
          responsiveLayout="scroll">
          <template #header>
            <div class="table-header">
              Vínculos - {{ vinculoEntidade.entidade.nome }}
            </div>
            <span class="mt-2 mr-2 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%" />
            </span>
            <Button
              icon="pi pi-filter-slash"
              label="Limpar"
              class="mt-2 mr-2 p-button-outlined"
              @click="limparFiltro" />
            <Button
              icon="pi pi-plus"
              class="mt-2 mr-2 p-button-success"
              @click="addVinculoEntidade()" />
          </template>
          <template #empty> Nenhuma vínculo associado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column field="codigoVinculo" header="Código do Vínculo"></Column>
          <Column field="vinculo.nome" header="Nome"></Column>
          <Column field="maxParcelas" header="Máximo de Parcelas"></Column>
        </DataTable>

        <Dialog
          header="Header"
          :visible.sync="exibirNovo"
          :modal="true"
          :containerStyle="{ width: '50vw' }"
          class="p-fluid"
          @hide="fecharDialog">
          <template #header>
            <h3>Associar novo vínculo</h3>
          </template>

          <div class="field">
            <label for="vinculo">Vínculo</label>
            <Dropdown
              id="vinculo"
              v-model="v$.vinculoEntidade.vinculo.$model"
              :options="vinculos"
              optionLabel="nome"
              scrollHeight="150px"
              placeholder="Selecione o vínculo"
              :class="{
                'p-invalid': v$.vinculoEntidade.vinculo.$invalid && submitted,
              }" />
            <small
              v-if="v$.vinculoEntidade.vinculo.$invalid && submitted"
              class="p-error"
              >Vínculo é obrigatório</small
            >
          </div>

          <div class="field">
            <label for="entidade">Entidade</label>
            <InputText
              id="entidade"
              v-model="vinculoEntidade.entidade.nome"
              required="true"
              disabled />
          </div>

          <div class="field">
            <label for="codigoVinculo">Código do vínculo</label>
            <InputText
              id="codigoVinculo"
              v-model="v$.vinculoEntidade.codigoVinculo.$model"
              required="true"
              :class="{
                'p-invalid':
                  v$.vinculoEntidade.codigoVinculo.$invalid && submitted,
              }"
              autofocus />
            <small
              v-if="v$.vinculoEntidade.codigoVinculo.$invalid && submitted"
              class="p-error"
              >Código do vínculo é obrigatório</small
            >
          </div>

          <div class="field">
            <label for="maxParcelas">Máximo de parcelas</label>
            <InputNumber
              id="maxParcelas"
              v-model="v$.vinculoEntidade.maxParcelas.$model"
              :min="0"
              required="true"
              :class="{
                'p-invalid':
                  v$.vinculoEntidade.maxParcelas.$invalid && submitted,
              }"
              autofocus />
            <small
              v-if="v$.vinculoEntidade.maxParcelas.$invalid && submitted"
              class="p-error"
              >Máximo de parcelas é obrigatório</small
            >
          </div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="exibirNovo = false" />
            <Button
              label="Salvar"
              icon="pi pi-check"
              autofocus
              @click="salvarVinculoEntidade" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import EntidadeService from '@/service/EntidadeService'
import VinculoService from '@/service/VinculoService'
import VinculoEntidadeService from '@/service/VinculoEntidadeService'

export default {
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      filtros: {},
      loading: false,
      vinculosEntidade: null,
      vinculoEntidade: {
        entidade: {},
        codigoVinculo: '',
        maxParcelas: 0,
        vinculo: {},
      },
      vinculos: null,
      exibirNovo: false,
      vinculoSelecionado: null,
      submitted: false,
    }
  },

  validations() {
    return {
      vinculoEntidade: {
        codigoVinculo: { required },
        maxParcelas: { required },
        vinculo: { required },
        entidade: { required },
      },
    }
  },

  created() {
    this.entidadeService = new EntidadeService(this.$http)
    this.vinculoEntidadeService = new VinculoEntidadeService(this.$http)
    this.vinculoService = new VinculoService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.loading = true
    if (!this.$route.params.entidade) {
      this.entidadeService.getEntidade(this.$route.params.id).then((res) => {
        this.vinculoEntidade.entidade = res
        this.loading = false
      })
    } else {
      this.vinculoEntidade.entidade = this.$route.params.entidade
    }

    this.vinculoEntidadeService
      .getListaVinculosDaEntidade(this.$route.params.id)
      .then((res) => {
        this.vinculosEntidade = res
        this.loading = false
      })
  },

  methods: {
    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    addVinculoEntidade() {
      this.exibirNovo = true
      this.vinculosLoading = true
      this.vinculoService.getListaVinculos().then((res) => {
        this.vinculos = res
        this.vinculosLoading = false
      })
    },

    async salvarVinculoEntidade() {
      this.submitted = true
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      this.vinculoEntidadeService
        .saveVinculoDaEntidade(this.vinculoEntidade)
        .then((res) => {
          if (this.vinculoEntidade.id) {
            // ATUALIZAR
          } else {
            this.vinculosEntidade.push(res.data)
            this.$toast.add({
              severity: 'success',
              summary: 'Vínculo associado com sucesso!',
              life: 10000,
            })
          }
          this.exibirNovo = false
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
          this.exibirNovo = false
        })
    },

    fecharDialog() {
      this.v$.$reset()
      this.submitted = false
      this.vinculoEntidade.vinculo = {}
      this.vinculoEntidade.codigoVinculo = ''
      this.vinculoEntidade.maxParcelas = 0
    },
    // mudarVinculo(slotProps) {
    //   this.$set(slotProps.data, 'loading', true)
    //   let ativo = slotProps.data.ativo ? false : true
    //   let vinculoConsignataria = {
    //     id: slotProps.data.idVinculo,
    //     vinculo: { id: this.$route.params.idvinculo },
    //     consignataria: { id: slotProps.data.idConsignataria },
    //     ativo: ativo,
    //   }
    //   this.vinculoConsignatariaService
    //     .saveVinculoConsignataria(vinculoConsignataria)
    //     .then((res) => {
    //       this.$set(slotProps.data, 'loading', false)
    //       let index = this.findIndexByIdConsignataria(res.data.consignataria.id)
    //       this.vinculos[index].ativo = res.data.ativo
    //       this.vinculos[index].idConsignataria = res.data.consignataria.id
    //       this.vinculos[index].idVinculo = res.data.id
    //       this.$toast.add({
    //         severity: 'success',
    //         summary: 'Alterado com sucesso!',
    //         life: 10000,
    //       })
    //     })
    //     .catch(() => {
    //       this.$set(slotProps.data, 'loading', false)
    //       this.$toast.add({
    //         severity: 'error',
    //         summary: 'Ocorreu um erro!',
    //         life: 10000,
    //       })
    //     })
    // },
  },
}
</script>

<style></style>
